.screen {
  padding: 24px 16px;
  height: calc(100vh - 87px) !important;
  margin-bottom: 20px;

  margin: 0 auto;
}
.nav-menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  font-family: "InterBold", sans-serif;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 24px;
}
.user-name-save-button {
  visibility: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 16px;
  width: 175px;
  height: 60px;
  background: #000000;
  border: 1.5px solid #3a3a3a;
  backdrop-filter: blur(40px);
  border-radius: 50px;
  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #ffffff;
}
.wal-header-name {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 175px;
}
.edit-input {
  display: none;
  border: none;
  padding: 18px 16px;
  width: 175px;
  height: 60px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(40px);
  border-radius: 50px;
  outline: none;

  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #ffffff;
}

.close-edit {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: none;
}
.input-container.editing .close-edit {
  display: block;
}
.input-container {
  position: relative;
  display: inline-block;
}

.wal-connected-title {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  font-family: "InterBold", sans-serif;
  font-size: 20px;
  color: #9c59cc;
  margin-bottom: 24px;
  margin-top: 40px;
}
.wal-connected-actions {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.wal-connected-address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  width: 175px;
  height: 60px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(40px);
  border-radius: 50px;

  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #ffffff;
}
.wal-connected-staking {
  display: flex;
  justify-content: center;
  column-gap: 4px;
  align-items: center;
  padding: 18px 16px;
  width: 175px;
  height: 60px;
  box-sizing: border-box;
  background: #000000;
  opacity: 0.5;
  border: 1.5px solid #3a3a3a;
  backdrop-filter: blur(40px);
  border-radius: 50px;

  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #ffffff;
}
.wal-transaction-actions {
  font-family: "InterRegular", sans-serif;
  font-size: 14px;
  color: #ffffff;

  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  row-gap: 17.5px;
  margin-bottom: 24px;
}
.radio-item label {
  display: flex;
  align-items: center;
}
.radio-item input[type="checkbox"] {
  appearance: none; /* Убираем стандартный вид радио кнопок */
  background: #ffffff24;
  box-sizing: border-box;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 4px;
  position: relative;
  cursor: pointer;
  outline: none;
}
.radio-item input[type="checkbox"]:checked {
  background: #ffffff24;
}
.radio-item input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background-color: #9c59cc;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.wal-cards {
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  margin-bottom: 8px;
}
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 16px;
  width: 114px;
  height: 50px;
  background: #000000;
  opacity: 0.5;
  border: 1.5px solid #3a3a3a;
  backdrop-filter: blur(40px);
  border-radius: 50px;

  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 16px;
  color: #ffffff;
}
.card-active {
  border: 1.5px solid #3a3a3a;
  backdrop-filter: none;
  opacity: 1;
}
.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 10px;
  width: 100%;
  height: 68px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(25px);
  border-radius: 16px;
}
.big-green {
  font-family: "InterBold", sans-serif;
  font-size: 16px;
  color: #03ff65;
}
.green {
  color: #03ff65;
}
.lavel {
  font-family: "InterBold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 4px;
}
.date {
  font-family: "InterRegular", sans-serif;
  font-size: 14px;
  color: #ffffff;
}
.history-info{
  flex: 1;
}
.history-sum {
  font-family: "InterRegular", sans-serif;
  font-size: 20px;
  color: #ffffff;
  width: max-content;
}
.history {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 100px;
}

.select-labels {
  width: 100%;
  display: flex;
  gap: 8px;
}
.select-labels p {
  flex: 1;
  margin-bottom: 8px;
}

.wal-selects {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.wal-selects-button,
.wal-selects-button2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 16px;
  width: 100%;
  height: 60px;
  background: #000000;
  border: 1.5px solid #3a3a3a;
  backdrop-filter: blur(40px);
  border-radius: 50px;
  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #ffffff;
  margin-top: 22px;
}
.wal-selects .custom-select-wrapper,
.wal-selects .custom-select-wrapper2 {
  position: relative;
  display: inline-block;
  user-select: none;
}

.wal-selects .custom-select,
.wal-selects .custom-select2 {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;
  width: 175px;
  height: 60px;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(40px);
  border-radius: 50px;
}

.wal-selects .custom-select-trigger,
.wal-selects .custom-select-trigger2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.wal-selects .custom-options,
.wal-selects .custom-options2 {
  display: none;
  position: absolute;
  flex-direction: column;
  top: 100%;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: flex-start;
  padding: 18px 16px;
  width: 100%;
  height: auto !important;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(40px);
  border-radius: 0px 0px 20px 20px;
  gap: 10px;
}
.wal-selects .custom-option {
  height: 32px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  vertical-align: middle;
}
.wal-selects .custom-options,
.wal-selects .custom-options2,
.wal-selects .custom-select-trigger,
.wal-selects .custom-select-trigger2 {
  font-family: "SF Pro Text Medium", sans-serif;
  font-size: 20px;
  color: #fff;
  /* overflow: scroll; */
}
.wal-selects .custom-options .custom-option:hover {
  color: #9c59cc;
}
.wal-selects .custom-options2 .custom-option2:hover {
  color: #9c59cc;
}
.wal-selects .custom-select.open .custom-options {
  display: flex;
}
.wal-selects .custom-select.open {
  border-radius: 20px 20px 0 0;
}
.wal-selects .custom-select.open .arrow-top {
  display: block;
}
.arrow-top {
  display: none;
}
.wal-selects .custom-select.open .arrow-down {
  display: none;
}
.wal-selects .custom-select.open {
  /* margin-bottom: 100px; */
}
.wal-selects .custom-select2.open {
  margin-bottom: 100px;
}
.wal-selects .custom-select2.open .custom-options2 {
  display: flex;
}
.wal-selects .custom-select2.open {
  border-radius: 20px 20px 0 0;
}
.wal-selects .custom-select2.open .arrow-top2 {
  display: block;
}
.arrow-top2 {
  display: none;
}
.custom-select2.open .arrow-down2 {
  display: none;
}

@media screen and (max-width: 389px) {
  .wal-selects .custom-select,
  .wal-selects .custom-select2,
  .user-name-save-button,
  .wal-header-name,
  .edit-input {
    width: 169px;
  }
  .wal-selects .custom-options,
  .wal-selects .custom-options2 {
    /* width: 169px; */
  }
}
@media screen and (max-width: 375px) {
  .wal-selects .custom-select,
  .wal-selects .custom-select2,
  .user-name-save-button,
  .wal-header-name,
  .edit-input {
    width: 163px;
  }
  .wal-selects .custom-options,
  .wal-selects .custom-options2 {
    /* width: 163px; */
  }
}
@media screen and (max-width: 360px) {
  .wal-selects .custom-select,
  .wal-selects .custom-select2,
  .user-name-save-button,
  .wal-header-name,
  .edit-input {
    width: 155px;
  }
  .wal-selects .custom-options,
  .wal-selects .custom-options2 {
    width: 155px;
  }
}

.wal-selects .custom-select,
.wal-selects .custom-select2,
.wal-selects .custom-options,
.wal-selects .custom-options2{
    background: #333350;
}
