.tasks-screen {
    padding: 14px 0 93px 0;
    margin-bottom: 20px;
    
    margin: 0 auto;
    overflow-y: auto;
}
.nav-menu-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nav-menu-items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    height: 87px;
    padding-top: 4.5px;
    position: fixed;
    width: 100%;
    right: 0;
    padding-right: 22.5px;
    padding-left: 22.5px;
    bottom: 0;
    background: #000;
}
.nav-menu-items-content{
    
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-menu-item-title{
    font-family: 'InterRegular',sans-serif;
    font-size: 10px;
    color: #fff;
}
.menu-item-active svg path{
    stroke: #9C59CC;
}
.menu-item-active .nav-menu-item-title{
    color: #9C59CC;
}
.tasks-title{
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.tasks-title-h2{
    font-family: 'InterBold',sans-serif;
    font-size: 28px;
    color: #FFFFFF;
    margin-bottom: 14px;
    text-align: center;
}
.tasks-types{
    width: 100%;
    display: flex;
}
.active,
.completed{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    box-sizing: border-box;
    width: 50%;
    height: 50px;
    background: rgba(255, 255, 255, 0.14);
    backdrop-filter: blur(40px);
    border-radius: 20px 20px 0px 0px;

    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
}
.completed-item{
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    box-sizing: border-box;
    padding: 14px 10px;
    width: 338px;
    height: 91px;
    backdrop-filter: blur(25px);
    border-radius: 16px;
    background: #FFFFFF24;
}

.tasks-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 26px;
    row-gap: 10px;
    overflow: hidden;
    width: 100%;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 0px 0px 20px 20px;
}
.type-desactive{
    background: rgba(255, 255, 255, 0.17);
    opacity: 0.5;
    backdrop-filter: blur(40px);
}
.tasks-item{
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    box-sizing: border-box;
    padding: 14px 10px;
    width: 338px;
    height: 91px;
    background: linear-gradient(270deg, rgba(95, 45, 202, 0.6) 0%, rgba(0, 0, 0, 0.6) 63.5%);
    backdrop-filter: blur(25px);
    border-radius: 16px;
}
.tasks-item[data-available="0"],.completed-item[data-available="0"]{
    background: #E0286433 !important;
}
.tasks-title{
    font-family: 'InterBlack',sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    margin: 0;
}
.tasks-subtitle{
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: 'InterRegular',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
}
.tasks-rep{
    font-family: 'InterRegular',sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    column-gap: 2px;
}
.tasks-rep img{
    width: 30px;
    height: 30px;
}
.big-green{
    color: #03FF65;
    font-family: 'InterBold',sans-serif;
}
.tasks-content{
    margin-left: 10px;
    flex: 1;
    max-width: -webkit-fill-available;
}
.tasks-item{

}
.tasks-items-completed{
    display: none;
}

.task-video-attrs{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
}
.task-video-attrs .result-txt{
    font-size: 18px;
    text-decoration: underline;
}

.task-video-tag{
    background-color: rgba(255, 255, 255, 0.14);
    padding: 2.5px 10px;
    border-radius: 50px;
    font-size: 12px;
}
.tasks-timer{
    background-color: #000;
    font-size: 12px;
    border-radius: 50px;
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    min-width: 80px;
}
.task-filter{
    flex: 1;
}
.video-task-filter-btn{
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
    align-items: center;
    width: 100%;
    font-family: 'InterRegular',sans-serif;
}
.video-task-filter-btn svg{
    transition: .2s;
    transform: rotate(180deg);
}

.video-task-filter-btn[data-opened="true"] svg
{
    transform: rotate(0deg);
}
.video-filter-tags-list{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 10px;
    overflow: hidden;
}
.video-filter-tags-list li{
    list-style: none;
    background: rgba(255, 255, 255, 0.17);
padding-top: 4px;
padding-right: 14px;
padding-bottom: 4px;
padding-left: 14px;
gap: 6px;
border-radius: 6px;
font-weight: 400;
font-size: 14px;
line-height: 16.94px;
letter-spacing: 0px;
text-align: center;
}

.video-filter-tags-list li[data-active="true"]{
    background: linear-gradient(270deg, #6633D4 0%, #0039A6 100%);
}
