#root{
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
}
body,html {
    max-width: 500px;
}
body {
    font-family: 'InterRegular', sans-serif;
    font-size: 14px;
    color: #ffffff;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE/Edge */
    user-select: none;
    /* Стандартный синтаксис */
}
img {
    user-select: none;
}
a,a:visited{
    color: rgb(0, 183, 255);
}
@font-face {
    font-family: 'InterBold';
    src: url('./fonts/Inter-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'InterMedium';
    src: url('./fonts/Inter-Medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'InterRegular';
    src: url('./fonts/Inter-Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'SF Pro Text Medium';
    src: url('./fonts/SFProText-Medium.ttf');
    font-weight: 500;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
html,
body {
    overflow-x: hidden;
}
.body-gradient {
    background: url(https://test.cardistrio.ru/static/media/bg-light-glow.9ee7918d2cbc81824592.png) no-repeat fixed center/cover;
}
.page-wrapper {
    position: absolute;
    overflow: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100vh;
    max-width: 500px;
}
.page-wrapper:has(.screen-task-inner){
    overflow: hidden;
}
.nav-menu-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.nav-menu-items {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    height: 87px;
    padding-top: 4.5px;
    position: fixed;
    width: 100%;
    right: 0;
    padding-right: 22.5px;
    padding-left: 22.5px;
    bottom: 0;
    background: #000;
}
.nav-menu-items-content{
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-menu-item-title{
    font-family: 'InterRegular',sans-serif;
    font-size: 10px;
    color: #fff;
}
.menu-item-active svg path{
    stroke: #9C59CC;
}

.menu-item-active .nav-menu-item-title{
    color: #9C59CC;
}

/* IMAGE COMPONENT */

.image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-placeholder{
    background-color: var(--tg-theme-hint-color);
    opacity: 0.3;
}
.image-placeholder-loader{
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-placeholder-loader::after{
    content: '';
    display: block;
    width: 200%;
    height: 100%;
    background: linear-gradient(90deg,
     rgba(255,255,255,0) 0%,
     rgb(255, 255, 255) 50%,
     rgba(255,255,255,0) 100%
    );
    opacity: 0.2;
    animation: image-loading 0.5s linear infinite;
}

@keyframes image-loading {
    0%{
        transform: translateX(-100%);
    }
    50%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.full-height {
    height: 100vh !important;
}
.size-24{
    width: 24px !important;
    height: 24px !important;
}