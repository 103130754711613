body{
    background-image: url(../img/bg-light-glow.png);
    background-repeat: no-repeat;
    background-size: cover;
    
    margin: 0 auto;
}
.screen {
    padding: 24px 16px 0 16px;
    
    margin: 0 auto;
    height: 100vh;
    position: relative;
}

.main-level-info-rep{
    font-family: 'InterBold',sans-serif;
    font-size: 20px;
    color: #03FF65;
}
.main-level-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.main-level-info-next{
    font-family: 'InterRegular',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 4px;

}
.video-play{
    position: relative;
    font-size: 25px;
    display: flex;
    align-items: center;
    gap: 6px;
    width: 140px !important ;
    height: 60px !important;
}
.video-play .counter{
    position: absolute;
    font-size: 14px;
    padding: 2px 4px;
    top: 0;
    right: 0;
    z-index: 1;
    font-family: 'InterRegular',sans-serif;
    transform: translate(5px,-5px);
    font-weight: normal;
    background: #DF2764;
    border-radius: 50px;
}

.coinsCount img{
    width: 60px;
    height: 60px;
}

.video-play img{
    width: 24px !important;
    height: 24px !important;
}

.count{
    font-family: 'InterRegular',sans-serif;
    font-size: 25px;
    color: #FFFFFF;
}
.coinsCount{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.coinsCount-button{
    box-sizing: border-box;
    height: 60px;
    align-items: center;
    padding: 8px 14px;
    width: 161px;
    background: rgba(255, 255, 255, 0.14);
    display: flex;
    justify-content: center;

    font-family: 'InterBold',sans-serif;
    font-size: 25px;
    color: #FFFFFF;
    border-radius: 50px;
    position: relative;
}
.coinsCount-button::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: 1;
    border-radius: inherit;
    border: 2px solid transparent;
    background: linear-gradient(270deg, #6633D4 0%, #0039A6 100%);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.main-info-bar{
    width: 100%;
    height: 23px;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 50px;
    margin-bottom: 34.5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.main-info-line{
    width: 134px;
    background-color: #03FF65;
    height: 17px;
    border-radius: 50px;
    margin-left: 3px;
}
.farming{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 14px;
    cursor: pointer;
    width: 338px;
    height: 60px;
    background: #2B2B4A;
    border-radius: 50px;
    position: absolute;
    bottom: 111px;
    left: calc(50% - 169px);
}
.farming-start{
    box-sizing: border-box;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 15px 18px 15px 14px;

    width: 338px;
    height: 60px;
cursor: pointer;
    background: #2B2B4A;
    border: 2px solid #6633D4;
    border-radius: 50px;
    position: absolute;
    bottom: 111px;
    left: calc(50% - 169px);

    font-family: 'InterMedium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.farming-start::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: inherit;
    border: 2px solid transparent;
    background: linear-gradient(270deg, #6633D4 0%, #0039A6 100%);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.farming-reward{
    display: flex;
    align-items: center;
    column-gap: 3px;
    z-index: 1;
}
.farming-reward img{
    width: 30px;
    height: 30px;
}
.farming-reward-count{
    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}
.farming-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 14px;
    color: #fff;
}
.farming-hour{
    font-family: 'InterRegular',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    z-index: 3;
}
.farming-line-container{
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    border-radius: 50px;
    top: calc(50% - 61px/2 + 0.5px);
    z-index: 1;
}
.farming-line{
    box-sizing: border-box;
    width: 150px;
    height: 60px;
    background: linear-gradient(270deg, #6633D4 0%, #0039A6 100%);
}
.menu-item-active svg path{
    stroke: #9C59CC;
}
.menu-item-active .nav-menu-item-title{
    color: #9C59CC;
}
.farming-click-area{
    width: 200px;
    height: 300px;
    cursor: default;
    z-index: 0 !important;
}
.farming-img {
    position: absolute;
    z-index: -1;
    width: 100vw;
    left: 50%;
    bottom: 33px;
    transform: translateX(-50%);
}
.farming-claim{
    font-family: 'InterBold',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
    z-index: 100;
    display: none;
}
.farming-text{
    z-index: 3;
}
.luna{
    position: absolute;
    z-index: -1;
    width: 96px;
    height: 96px;
    left: 0px;
    bottom: 280px;
}
.luna-1,
.luna-2,
.luna-3{
    display: none;
}
.tuman-1{
    display: none;
}
.tuman {
    left: calc(50% - 50vw);
    bottom: -28px;
    z-index: -1;
    position: absolute;
    width: 100vw;
}
.thing{
    font-family: "InterRegular",sans-serif;
    font-size: 20px;
    font-weight: 400;
}
.notification{
    width: 100%;
    height: 100%;
    min-height: 55px;
    background: #251B8BA3;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    backdrop-filter: blur(25px);
    padding: 64px 20px 0;
    overflow: auto;
}
.notification.square{
    height: 390px;
    top: 105px;
}
.notification-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 16px;
    line-height: 120%;
    color: #ffffff;
}
.close-notification svg{
    width: 100%;
    height: 100%;
}
.close-notification svg path{
    stroke: #9C59CC;
}
.close-notification{
    position: absolute;
    top: 13px;
    width: 50px;
    height: 50px;
    border: none;
    background:none;
    right: 13px;
}
.disabled {
    pointer-events: none;
    cursor: not-allowed;
}

@media  screen and (max-width: 425px){

}
@media  screen and (max-width: 380px){
    .count {
        font-size: 23px;
    }
    .coinsCount img {
        width: 58px;
        height: 58px;
    }
    .coinsCount-button {
        height: 58px;
        width: 157px;
    }
}
@media  screen and (max-width: 360px){
    .count {
        font-size: 22px;
    }
    .coinsCount img {
        width: 56px;
        height: 56px;
    }
    .coinsCount-button {
        height: 56px;
        width: 153px;
    }
}














