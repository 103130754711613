
.vip-bg{
    background: url("./img/upgrade-card-inner-vip-bg.png") no-repeat center/cover !important;
}
.upgrade-modal-screen {
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px 16px 14px 16px;
    height: calc(100vh - 87px) !important;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    background: url("./img/bg-light-glow.png") no-repeat center/cover;
}
.nav-menu-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title{
    margin: 0;
    font-family: 'InterBold',sans-serif;
    color: #fff;
    font-size: 28px;
}
.up-standart-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.up-standart-image{
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
    display: flex;
    justify-content: center;

}
.up-standart-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 20px;
    max-width: 342px;
}
.upgrade-farming{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.farming-coin{
    display: flex;
    align-items: center;
}
.farming-coin img{
    width: 40px;
    height: 40px;
    margin-right: 4px;
}
.farming-coin-text,
.farming-rep-text{
    font-family: 'InterRegular',sans-serif;
    font-size: 18px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 4px;
}
.bold{
    font-weight: 700;
    margin-right: 4px;
}
.green-bold{
    font-weight: 700;
    margin-right: 4px;
    color: #03FF65;
}
.farming-rep{
    display: flex;
    align-items: center;
}
.up-standart-button img{
    height: 40px;
    width: 40px;
}
.up-standart-button{
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 16px;
    column-gap: 4px;
    width: 100%;
    height: 60px;
    background: #000000;
    border: 1.5px solid #3A3A3A;
    backdrop-filter: blur(40px);
    border-radius: 50px;

    font-family: 'SF Pro Text',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}

.up-vip-text{
    margin-bottom: 78px;
}
.up-vip-cost{
    display: flex;
    padding-left: 23.4px;
    padding-right: 30px;
    justify-content: space-between;
}
.star{
    display: flex;
    align-items: center;
    column-gap: 5.7px;
}
.rubin{
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.star img{
    width: 41px;
    height: 39px;
}
.rubin img{
    width: 34px;
    height: 33px;
}
.rubin-text,
.star-text{
    font-family: 'SF Pro Text Medium',sans-serif;
    font-size: 20px;
    color: #FFFFFF;
}

.upgrade-tag{
    padding: 3px 10px;
    border-radius: 50px;
    background-color: hsla(0, 0%, 100%, 0.144);
    margin-bottom: 10px;
}

.up-standart-button-list{
    display: flex;
    gap: 8px;
    align-items: center;
}
.up-standart-button-list > *{
    flex: 1 1 calc(50% - 8px);
}
.up-standart-button-list li{
   list-style: none;
}